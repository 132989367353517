
import firebase from 'firebase'
import moment from 'moment';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_GFBAPI_KEY,
  authDomain: "instatap-portal.firebaseapp.com",
  projectId: "instatap-portal",
  storageBucket: "instatap-portal.appspot.com",
  messagingSenderId: "23312844102",
  appId: "1:23312844102:web:cd465a256dcb25b4efd147",
  measurementId: "G-F1W9B8CRGP"
};


firebase.initializeApp(firebaseConfig);
const db = firebase.firestore()





const adminUID = process.env.VUE_APP_ADMIN_KEY


const notification = (ptner, obj) =>{
  db.collection('notifications').add({
    reason: obj.kind,
    msg: obj.msg,
    date: moment().format('MMM DD hh:mm a'),
    name: `${ptner.fname} ${ptner.lname}`,
    read: false,
})
}


const customSearch = (array, e) => {
  return array.filter(i => {
    return i.fname.toLowerCase().match(e.toLowerCase()) 
    || i.lname.toLowerCase().match(e.toLowerCase())
 })
}

class costCollection extends Array {
  sum(key) {
      return this.reduce((a, b) => parseFloat(a) + parseFloat((b[key]) || 0), 0);
  }
}


export{
   db, notification, customSearch, adminUID, costCollection
}









