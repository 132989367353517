<template>
    <div>
      <h1 class="logo">
        <img src="../assets/logo-raw-removebg.png" alt="">
      </h1>
          <h4>One Stop Shop For Business Solutions</h4>

          <div class="circle">
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path fill="#FF8F14" d="M60.1,-22C66.8,0.9,53.3,27.9,32.9,41.9C12.4,56,-15,57.2,-31.9,44.7C-48.8,32.3,-55.1,6.1,-48.1,-17.3C-41,-40.8,-20.5,-61.5,3.1,-62.6C26.7,-63.6,53.5,-44.8,60.1,-22Z" transform="translate(100 100)" />
              </svg>
          </div>
          <div class="drop">
           <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path fill="#222222" d="M58.5,-20.7C61.6,-9.2,40.4,8.5,16.8,26.4C-6.7,44.2,-32.5,62.2,-45,54.8C-57.6,47.4,-56.9,14.6,-47.1,-5.9C-37.4,-26.4,-18.7,-34.6,4.5,-36.1C27.6,-37.5,55.3,-32.2,58.5,-20.7Z" transform="translate(100 100)" />
          </svg>
          </div>
    </div>
</template>

<script>
export default {
    name: 'AuthLeft'
}
</script>



<style lang="scss" scoped>
@import '../assets/scss/style.scss';







</style>