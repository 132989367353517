<template>
  <div class="home">
    <div class="auth-form">
      <div class="flex">
         <div class="auth-empty">
            <auth-left></auth-left>
          </div>
        <div class="form">
           <div class="form-controller">
              <form @submit.prevent="submit" class="form-control__content">
                <h2 class="cente">Sign up</h2>
                  <div class="control mt-12 flex">
                      <b-input class="w-1-2" v-model="form.fname" size="is-medium" placeholder="First Name*" required></b-input>
                      <b-input class="w-1-2 ml-3" v-model="form.lname" size="is-medium" placeholder="Last Name*" required></b-input>
                  </div>
                  <div class="control mt-12">
                      <b-input type="email" v-model="form.email" size="is-medium" placeholder="Work email address*" required></b-input>
                  </div>
                  <div class="control mt-12">
                      <b-input size="is-medium" v-model="form.phone" placeholder="Phone number"></b-input>
                  </div>
                    <div class="control mt-12">
                      <b-input size="is-medium" type="password" v-model="form.password" placeholder="Password*" required></b-input>
                  </div>
                  <div class="flex flex-left mt-5 mb-5">
                    <b-checkbox required></b-checkbox><span class="tos">I agree to Instatap <a href="#" @click.prevent="dialog = true">Terms and Conditions</a></span>
                  </div> 
                  <b-button native-type="submit" type="is-primary" size="is-medium" expanded>Register</b-button>
                  <p class="center mt-5">Already have an account? <router-link to="/login">Log in</router-link></p>
              </form>
           </div>
        </div>
      </div>
    </div>

    
        <b-modal v-model="dialog" :width="350">
            <div class="moda-card">
                <div class="modal-card-head">
                    <h2>Terms & Conditions</h2>
                </div>
                <section class="modal-card-body">
                  <p>1. You must have permission from the person that is being referred to let them know that an agent from Instatap will contact him/her before uploading a lead to the portal.</p>
                  <p class="mt-3 mb-4">2. Residual payments, if any, will be added to the introducer's account each month.</p>
                  <p class="mb-3">3. Minimum withdrawal amount is £100</p>
                  <p>4. Residual payments will continue as long as merchant account is active and trading.</p>
                </section>
            </div>
        </b-modal>


  </div>
</template>

<script>
import firebase from 'firebase'
import { db, notification } from '../utils'
import AuthLeft from '@/components/AuthLeft.vue';

export default {
  components: { AuthLeft },
  name: 'Home',
  data: () => ({
    form: {}, dialog: false,
  }),
  methods: {
    submit(){
      firebase.auth().createUserWithEmailAndPassword(this.form.email, this.form.password)
        .then((userCredential) => {
          const user = userCredential.user;
          this.savePartner(user)
          this.$router.push('/login')
        })
        .catch((error) => {
          this.$buefy.toast.open({
              message: error.message,
              type: 'is-warning'
          })
        });
    },
    async savePartner(user){
        let partnerConstruct = {...this.form,... {partner: user.uid, earned: '0.00'}}
        partnerConstruct.password = null
        db.collection('partners').add(partnerConstruct)
        notification(partnerConstruct, {
            kind: 'sign up', 
            msg: `Partner recently signed up an account. Completed details can be found on the partner tab`
        })
    }
  }
}
</script>


<style lang="scss" scoped>
@import '../assets/scss/style.scss';

.control{
  margin-top: 20px;
}

</style>
