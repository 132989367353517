import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/m/app',
    name: 'Partner',
    component: () => import('../views/partner/Partner.vue')
  },
  {
    path: '/m/clients',
    name: 'Customers',
    component: () => import('../views/partner/Customer.vue')
  },
  {
    path: '/admin/app',
    name: 'Leads',
    component: () => import('../views/admin/Leads.vue')
  },
  {
    path: '/admin/partners',
    name: 'Partners',
    component: () => import('../views/admin/Partners.vue')
  },
  {
    path: '/login',
    name: 'PartnerLogin',
    component: () => import('../views/auth/PartnerLogin.vue')
  },
  {
    path: '/reset-password',
    name: 'Reset',
    component: () => import('../views/auth/Reset.vue')
  },
  {
    path: '/admin',
    name: 'AdminLogin',
    component: () => import('../views/auth/AdminLogin.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
